import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Application from './App.jsx';

ReactDOM.render(
    <Application />,
  document.getElementById('root')
);


